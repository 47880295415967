import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 712.000000 712.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,712.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M1805 5921 c-72 -4 -139 -11 -151 -18 -17 -10 -95 -90 -284 -294 -30
-32 -60 -66 -160 -179 -41 -47 -80 -89 -87 -95 -6 -5 -30 -32 -53 -60 -23 -27
-60 -69 -82 -93 -37 -40 -39 -46 -33 -84 8 -46 -2 -58 -50 -58 -33 0 -88 -54
-331 -326 -78 -88 -103 -115 -173 -189 -31 -33 -73 -81 -94 -105 -21 -25 -63
-70 -93 -102 -47 -49 -54 -61 -54 -97 0 -41 0 -41 -38 -41 -31 0 -45 -7 -80
-43 l-42 -43 0 -738 c0 -587 3 -740 13 -749 42 -39 59 -47 122 -57 97 -15 477
-20 610 -8 l110 11 90 91 c94 95 266 282 326 351 47 55 202 229 209 235 30 25
65 89 65 119 0 28 4 36 20 39 11 1 31 5 45 8 15 3 90 72 186 170 89 90 166
164 172 164 17 0 17 0 200 -230 132 -166 155 -190 184 -190 36 0 48 -17 36
-51 -9 -27 -2 -40 123 -212 73 -100 136 -184 139 -187 3 -3 35 -43 70 -90 80
-106 197 -245 241 -286 19 -17 59 -48 89 -70 l55 -39 370 0 c439 0 401 -9 520
130 63 74 120 139 163 183 19 20 32 42 29 51 -2 9 -73 108 -158 220 -85 111
-164 217 -177 235 l-23 33 22 42 c12 23 19 45 16 48 -4 3 -25 1 -47 -5 -33 -8
-43 -7 -56 6 -17 16 -305 396 -387 510 -27 37 -65 87 -85 112 -21 25 -51 65
-67 90 -17 25 -36 51 -43 58 -32 35 -62 81 -62 96 0 10 7 24 15 33 9 8 15 23
13 32 -3 12 -13 15 -49 13 -45 -3 -45 -3 -90 58 -24 35 -49 67 -54 74 -6 6
-59 76 -119 156 -59 80 -121 162 -137 182 -17 21 -56 73 -87 115 -31 43 -59
80 -62 83 -4 3 -21 28 -40 55 -19 28 -36 52 -40 55 -26 22 -60 76 -60 93 0 12
19 42 43 67 23 25 76 83 117 130 41 47 77 87 80 90 47 46 120 135 120 146 0
24 -219 236 -285 276 -33 20 -95 48 -137 62 -71 24 -88 26 -245 24 -92 -1
-226 -4 -298 -7z"/>
<path d="M3288 5891 c-46 -9 -95 -42 -131 -90 -16 -19 -47 -56 -70 -81 -92
-98 -147 -167 -147 -184 0 -18 124 -193 275 -388 41 -54 75 -107 75 -119 0
-14 21 -41 54 -73 30 -28 100 -112 155 -186 55 -74 104 -140 110 -147 6 -6 80
-105 166 -220 85 -114 171 -228 191 -253 28 -36 34 -50 30 -75 -7 -38 5 -57
39 -60 34 -4 36 -7 170 -189 61 -82 115 -154 121 -160 18 -19 371 -492 382
-512 8 -14 6 -26 -6 -46 -15 -26 -14 -28 21 -80 20 -29 44 -64 54 -78 9 -14
37 -52 61 -84 24 -32 49 -73 55 -90 5 -17 17 -38 26 -47 9 -9 23 -27 31 -39
54 -82 208 -290 233 -315 l31 -30 115 3 c85 2 122 7 140 19 31 20 64 54 285
298 60 66 114 125 120 130 6 6 45 48 85 95 41 47 102 116 135 154 84 95 91
105 77 119 -6 6 -11 21 -11 34 0 18 6 23 24 23 33 0 67 14 89 36 150 158 272
295 270 305 -2 5 2 9 7 9 6 0 34 26 63 57 29 32 67 73 85 93 19 19 53 57 75
83 23 27 74 83 114 125 62 65 72 81 75 116 3 40 4 41 43 46 29 4 51 16 78 43
l37 38 0 741 0 740 -32 29 c-47 41 -127 52 -393 54 -234 2 -379 -7 -427 -26
-27 -11 -149 -133 -268 -270 -78 -89 -115 -130 -155 -173 -61 -66 -131 -144
-157 -175 -15 -17 -46 -55 -70 -82 -33 -39 -43 -59 -43 -86 0 -34 -2 -36 -47
-48 -43 -11 -65 -29 -206 -173 -87 -89 -164 -162 -170 -162 -7 0 -21 10 -31
23 -28 32 -116 143 -188 233 -100 128 -135 162 -168 166 -28 3 -30 5 -27 42 3
32 -5 50 -49 115 -29 42 -55 78 -58 81 -8 7 -93 120 -114 150 -70 105 -296
379 -354 431 -146 128 -108 119 -503 118 -184 -1 -352 -5 -372 -8z"/>
<path d="M2601 2087 c-50 -16 -115 -84 -137 -142 -27 -70 -25 -212 4 -276 37
-84 107 -128 203 -129 59 0 138 22 151 43 9 14 11 61 3 69 -3 3 -22 -3 -43
-13 -21 -11 -57 -19 -85 -19 -93 0 -142 55 -151 170 -7 85 10 149 54 193 34
33 43 37 89 37 32 0 67 -8 91 -20 22 -11 42 -20 45 -20 13 0 3 68 -12 82 -46
38 -138 49 -212 25z"/>
<path d="M3417 2093 c-36 -6 -59 -45 -117 -200 -16 -42 -27 -42 -46 3 -8 20
-28 63 -44 97 -17 33 -30 66 -30 73 0 6 -7 15 -16 18 -26 10 -94 7 -94 -4 0
-6 34 -78 75 -161 l75 -151 0 -104 c0 -104 0 -105 27 -115 15 -5 38 -8 50 -4
22 5 23 9 23 108 l0 103 74 150 c41 83 76 159 78 169 3 21 -9 25 -55 18z"/>
<path d="M3773 2089 c-22 -8 -22 -11 -23 -249 0 -132 2 -251 6 -264 7 -31 30
-34 169 -27 l110 6 3 32 3 32 -93 3 -93 3 -5 229 -5 230 -25 6 c-14 4 -35 3
-47 -1z"/>
<path d="M243 2083 c-10 -3 -13 -67 -13 -268 l0 -263 23 -6 c12 -3 33 -3 45 1
22 5 22 6 22 229 0 187 2 224 14 224 13 0 36 -56 65 -160 5 -19 25 -80 45
-135 20 -55 36 -107 36 -116 0 -9 7 -22 15 -29 15 -13 80 -18 91 -8 3 4 28 70
55 149 26 78 51 144 54 147 3 3 5 16 5 28 0 13 4 25 9 28 4 3 14 26 21 51 7
25 19 45 26 45 11 0 14 -41 14 -225 0 -239 -2 -227 45 -227 47 0 45 -15 45
266 0 231 -2 265 -16 270 -30 12 -131 6 -143 -8 -11 -13 -36 -76 -93 -236 -42
-121 -52 -140 -62 -137 -6 2 -23 39 -38 83 -16 43 -33 88 -38 99 -4 11 -16 45
-26 75 -20 64 -42 112 -58 122 -12 9 -124 9 -143 1z"/>
<path d="M1187 2083 c-10 -9 -8 -505 1 -520 6 -10 142 -17 280 -14 18 1 22 7
22 36 l0 34 -102 3 -103 3 -3 82 c-3 95 -2 96 96 90 61 -4 85 9 80 44 -3 23
-6 24 -90 27 l-88 3 0 74 0 74 103 3 102 3 3 33 3 32 -149 0 c-82 0 -152 -3
-155 -7z"/>
<path d="M1807 2083 c-4 -3 -7 -125 -7 -269 0 -250 1 -263 19 -269 11 -3 31
-3 45 1 25 6 25 7 28 118 l3 111 34 3 c49 5 82 -32 122 -138 29 -78 36 -90 61
-95 35 -8 68 2 68 21 0 25 -62 158 -92 196 -15 20 -28 39 -28 42 0 2 16 14 35
26 93 57 84 193 -15 242 -27 14 -61 18 -151 18 -64 0 -119 -3 -122 -7z m230
-90 c21 -22 24 -33 20 -67 -8 -57 -35 -76 -108 -76 l-59 0 0 85 0 85 61 0 c54
0 65 -3 86 -27z"/>
<path d="M4312 1818 l3 -272 32 -2 c58 -3 58 -4 55 284 l-3 262 -44 0 -45 0 2
-272z"/>
<path d="M4737 2083 c-4 -3 -7 -124 -7 -269 0 -253 1 -262 20 -267 11 -3 29
-3 40 0 19 5 20 14 20 209 0 157 3 204 13 204 6 -1 43 -63 80 -138 100 -200
123 -242 138 -258 16 -15 88 -22 107 -10 9 5 12 82 12 272 l0 265 -37 -3 -38
-3 -3 -193 c-1 -113 -6 -192 -12 -190 -5 2 -50 84 -100 183 -50 99 -99 186
-108 193 -16 12 -114 17 -125 5z"/>
<path d="M5497 2083 c-4 -3 -7 -124 -7 -268 l0 -262 38 -7 c55 -9 62 7 61 134
-2 147 -2 145 16 130 8 -6 49 -67 91 -135 l76 -123 45 -4 c59 -4 65 11 24 68
-17 25 -31 49 -31 53 0 5 -4 11 -8 13 -10 4 -102 149 -102 161 0 8 28 51 57
87 46 57 103 140 103 149 0 7 -19 11 -47 11 l-48 -1 -80 -119 c-43 -66 -83
-120 -87 -120 -9 0 -9 0 -12 110 -4 139 -1 130 -44 130 -21 0 -42 -3 -45 -7z"/>
<path d="M487 1324 c-4 -4 -7 -58 -7 -121 0 -107 1 -113 20 -113 20 0 21 5 18
117 -3 108 -11 137 -31 117z"/>
<path d="M617 1323 c-4 -3 -7 -57 -7 -120 0 -106 1 -113 20 -113 17 0 20 7 20
48 0 51 9 92 19 92 3 0 24 -32 46 -70 31 -54 45 -70 63 -70 22 0 23 1 20 118
-2 96 -6 117 -18 117 -12 0 -16 -16 -18 -72 -2 -40 -7 -73 -12 -73 -5 0 -23
26 -41 58 -48 86 -71 107 -92 85z"/>
<path d="M875 1320 c-10 -15 4 -28 35 -32 24 -3 25 -5 28 -100 2 -90 4 -98 22
-98 18 0 20 7 20 84 0 105 4 116 42 116 23 0 29 4 26 18 -3 14 -16 17 -85 20
-52 2 -84 -1 -88 -8z"/>
<path d="M1127 1324 c-4 -4 -7 -58 -7 -121 l0 -113 85 0 c68 0 85 3 85 15 0
10 -16 15 -62 17 l-63 3 0 35 0 35 58 3 c41 2 57 7 57 17 0 10 -16 15 -57 17
-55 3 -58 5 -61 30 -3 27 -2 27 60 30 48 2 63 7 63 18 0 12 -16 16 -75 18 -42
1 -79 0 -83 -4z"/>
<path d="M1370 1210 c0 -112 1 -120 19 -120 16 0 20 8 23 43 3 40 5 42 38 45
40 4 60 -16 60 -60 0 -22 5 -28 21 -28 19 0 21 4 15 48 -6 51 -7 55 -3 122 2
38 -1 46 -23 57 -14 7 -53 13 -87 13 l-63 0 0 -120z m135 45 c0 -29 -2 -30
-45 -30 -44 0 -45 1 -48 33 l-3 33 48 -3 c46 -3 48 -4 48 -33z"/>
<path d="M1647 1324 c-4 -4 -7 -58 -7 -121 0 -106 1 -113 20 -113 18 0 20 7
20 70 0 39 4 70 10 70 5 0 28 -31 51 -70 33 -56 46 -70 65 -70 l25 0 -3 118
c-2 96 -6 117 -18 117 -11 0 -16 -17 -20 -69 -3 -38 -9 -71 -15 -73 -5 -1 -28
30 -50 70 -37 67 -61 88 -78 71z"/>
<path d="M1970 1308 c-31 -78 -70 -190 -70 -203 0 -23 34 -18 45 8 8 17 20 23
52 25 36 3 44 -1 59 -22 16 -26 44 -35 44 -16 0 17 -51 172 -67 203 -17 33
-50 36 -63 5z m51 -75 c14 -39 8 -58 -21 -58 -15 0 -26 6 -28 16 -4 21 15 69
28 69 6 0 15 -12 21 -27z"/>
<path d="M2144 1319 c-9 -14 5 -27 36 -31 24 -3 25 -6 30 -98 4 -80 8 -95 23
-98 15 -3 17 6 17 81 0 105 4 117 41 117 22 0 29 5 29 20 0 18 -7 20 -84 20
-50 0 -88 -4 -92 -11z"/>
<path d="M2395 1318 c-3 -8 -4 -61 -3 -119 3 -90 5 -104 21 -107 16 -3 17 6
15 114 -2 83 -7 119 -15 122 -7 2 -15 -3 -18 -10z"/>
<path d="M2563 1315 c-29 -21 -54 -87 -47 -127 15 -82 74 -122 147 -101 87 24
106 187 26 228 -36 19 -99 19 -126 0z m112 -41 c29 -31 33 -80 10 -124 -13
-25 -22 -30 -50 -30 -51 0 -75 28 -75 89 0 42 4 53 26 70 36 28 60 26 89 -5z"/>
<path d="M2827 1323 c-4 -3 -7 -57 -7 -120 0 -106 1 -113 20 -113 18 0 20 7
20 71 0 88 11 87 63 -4 31 -54 43 -67 63 -67 l25 0 -3 118 c-2 96 -6 117 -18
117 -11 0 -16 -15 -18 -65 -4 -79 -15 -94 -39 -51 -60 110 -85 136 -106 114z"/>
<path d="M3152 1313 c-11 -21 -72 -203 -72 -215 0 -19 38 -6 48 17 10 21 18
25 57 25 39 0 47 -3 55 -25 6 -16 17 -25 31 -25 13 0 19 5 16 13 -2 6 -19 55
-37 107 -18 52 -37 101 -43 108 -14 18 -45 15 -55 -5z m48 -79 c17 -44 13 -64
-14 -64 -33 0 -37 10 -25 53 12 43 26 47 39 11z"/>
<path d="M3363 1313 c-6 -16 -8 -68 -4 -175 l1 -48 81 0 c60 0 80 3 77 13 -3
7 -28 14 -59 17 l-54 5 -3 97 c-2 74 -6 97 -18 102 -9 3 -17 -1 -21 -11z"/>
<path d="M3660 1313 c0 -10 15 -63 34 -118 31 -88 37 -100 58 -100 20 0 27 12
60 110 21 65 33 114 28 119 -16 16 -37 -12 -54 -72 -19 -65 -32 -90 -43 -79
-4 4 -16 40 -28 80 -14 50 -25 73 -37 75 -12 2 -18 -3 -18 -15z"/>
<path d="M3914 1317 c-2 -7 -3 -60 -2 -118 l3 -104 88 -3 c69 -2 88 0 85 10
-3 8 -28 15 -64 18 l-59 5 0 35 0 35 53 3 c68 4 65 25 -4 32 -45 5 -49 7 -52
33 -3 27 -3 27 58 27 53 0 61 2 58 18 -3 14 -16 17 -81 20 -58 2 -79 -1 -83
-11z"/>
<path d="M4167 1323 c-4 -3 -7 -57 -7 -120 0 -106 1 -113 20 -113 18 0 20 7
20 63 0 77 12 100 32 66 74 -121 81 -130 102 -127 20 3 21 8 19 117 -1 62 -4
116 -7 118 -3 3 -12 3 -21 0 -11 -5 -15 -23 -15 -77 0 -38 -4 -70 -8 -70 -4 0
-25 30 -47 68 -21 37 -44 70 -50 74 -13 10 -30 10 -38 1z"/>
<path d="M4427 1324 c-16 -17 -5 -33 26 -36 l32 -3 3 -97 c2 -89 4 -98 22 -98
18 0 20 9 22 97 l3 98 30 5 c18 3 30 11 30 20 0 12 -17 16 -80 18 -45 1 -84 0
-88 -4z"/>
<path d="M4677 1324 c-4 -4 -7 -50 -7 -101 0 -86 2 -96 25 -118 17 -18 35 -25
63 -25 77 0 100 44 94 181 -3 59 -6 70 -20 67 -14 -3 -18 -19 -20 -93 -2 -49
-8 -95 -14 -102 -13 -17 -64 -17 -75 0 -4 6 -10 52 -13 101 -5 82 -14 108 -33
90z"/>
<path d="M4947 1323 c-4 -3 -7 -57 -7 -120 0 -105 1 -113 19 -113 16 0 20 8
23 43 3 40 5 42 36 45 43 4 62 -12 62 -54 0 -27 4 -34 20 -34 17 0 20 7 20 43
0 23 -4 49 -10 57 -5 9 -5 19 1 26 6 7 9 30 7 51 -2 28 -9 41 -28 50 -26 13
-132 18 -143 6z m128 -68 c0 -29 -2 -30 -45 -30 -35 0 -45 4 -48 18 -7 37 6
48 50 45 40 -3 43 -5 43 -33z"/>
<path d="M5217 1323 c-4 -3 -7 -57 -7 -120 l0 -113 85 0 c68 0 85 3 85 15 0
10 -16 15 -62 17 l-63 3 0 35 0 35 53 3 c36 2 52 7 52 17 0 10 -16 15 -52 17
-49 3 -53 5 -56 31 -3 26 -2 27 47 27 60 0 81 7 74 26 -4 10 -24 14 -77 14
-40 0 -76 -3 -79 -7z"/>
<path d="M5470 1310 c-44 -44 -18 -96 61 -119 46 -14 54 -20 54 -41 0 -22 -5
-25 -37 -28 -27 -2 -43 3 -58 18 -21 21 -40 19 -40 -5 0 -41 93 -70 143 -44
30 16 37 28 37 70 0 32 -4 39 -34 53 -19 9 -43 16 -53 16 -11 0 -28 6 -39 14
-15 12 -17 18 -8 33 16 27 49 27 84 2 22 -17 33 -20 41 -12 9 9 5 18 -14 37
-34 34 -106 37 -137 6z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
